import React from "react";

import styled from "styled-components";

function Footer() {

  const d = new Date();
  return (
    <footer style={{ background: "#343a40" }}>
      <div style={{ marginTop: 5 }}>
        <center>
          {" "}
          Made with 💖 by
          <a target="_blank" href="https://github.com/mohitkadwe19" style={{ color: "#59b7ff" }}
          >
            {" "}
            mohitkadwe19{" "}
          </a>
          © {d.getFullYear()}
        </center>
        <br />
        <div className="git-footer" style={{ marginBottom: 5, marginTop: -10 }}>
          <a
            className="github-button"
            href="https://github.com/mohitkadwe19"
            style={{ marginRight: 10 }}
            aria-label="Follow @mohitkadwe19 on GitHub"
          >
            Follow @mohitkadwe19
          </a>
          <spacer />
        </div>
      </div>
    </footer>
  );
}

export default Footer;

const heart = styled.div`
  color: red;
`;
