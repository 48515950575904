import React, { Component } from "react";
import "./App.css";
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf'
import Profile from "./components/Profile";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default class App extends Component {
  constructor(props) {
    super(props);
  }

  printDocument() {
    const input = document.getElementById('divToPrint');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
      ;
  }


  render() {
    return (<div className="App">

      <Header />

      <Profile />

      <Footer>

      </Footer>

    </div>);
  }
}

